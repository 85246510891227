import React from 'react'
import propType from 'prop-types'
import cx from 'clsx'
import Fade from 'react-reveal/Fade'

import s from './indexUpgrade.module.scss'

const IndexUpgrade = ({ data }) => {
  const { title, explainText, buttonText, buttonLink, backgroundImage } = data

  return (
    <Fade bottom cascade>
      <div className={s.upgradeSection}>
        <div className="container">
          <div className={s.wrapper}>
            <img src={backgroundImage.url} alt={backgroundImage.alt} className={s.img} />

            <div className={s.textBox}>
              <h3 className={s.title}>{title[0].text}</h3>

              <a href={buttonLink.url} target="_blank" rel="noreferrer">
                <span className={cx('btn btn-secondary', s.button)}>
                  <span>{buttonText[0].text}</span>
                </span>
              </a>

              <span className={s.text}>{explainText[0].text}</span>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

IndexUpgrade.propTypes = {
  data: propType.shape({
    title: propType.array,
    explainText: propType.array,
    buttonText: propType.array,
    buttonLink: propType.object,
    backgroundImage: propType.object
  })
}

export default IndexUpgrade
