import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import Hero from 'src/components/IndexHero'
import Features from 'src/components/IndexFeatures'
import Solutions from 'src/components/IndexSolutions'
import Upgrade from 'src/components/IndexUpgrade'

export const query = graphql`
    {
        prismic {
            homepage(lang: "en-us", uid: "homepage") {
                featuresTitle
                headerButtonLink {
                    ... on PRISMIC__ExternalLink {
                        url
                    }
                }
                headerButtonText
                headerDescription
                headerImage
                headerTitle
                solutions {
                    text
                }
                features {
                    image
                    linkText
                    linkUrl {
                        ... on PRISMIC_Features {
                            _meta {
                                type
                                uid
                            }
                        }
                    }
                    title
                    description
                }
                solutionsImage
                subscription {
                    backgroundImage
                    buttonLink {
                        ... on PRISMIC__ExternalLink {
                            url
                        }
                    }
                    buttonText
                    explainText
                    title
                }
            }
        }
    }
`

const IndexPage = props => {
    const { homepage } = props.data.prismic
    const { features, featuresTitle, headerButtonLink, headerButtonText, headerDescription, headerImage, headerTitle, solutions, solutionsImage, subscription } = homepage

    const heroData = {
        title: headerTitle,
        description: headerDescription,
        linkText: headerButtonText,
        linkUrl: headerButtonLink,
        image: headerImage
    }
    const solutionsData = { items: solutions, image: solutionsImage }
    const featuresData = { title: featuresTitle, items: features }
    const upgradeData = { ...subscription[0] }

    return (
      <Layout>
          <SEO title="Home" />

          <Hero data={heroData} />

          <Solutions data={solutionsData} />

          <Features data={featuresData} />

          <Upgrade data={upgradeData} />
      </Layout>
    )
}

export default IndexPage
