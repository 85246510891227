import React from 'react'
import cx from 'clsx'
import propType from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import { Feature1Shape, Feature2Shape, Feature3Shape, Feature4Shape } from 'src/components/Svg'
import { linkResolver } from 'src/utils/prismic-configuration'

import s from './indexFeatures.module.scss'

const getRowData = index => {
  const num = index + 1
  const rowNum = num < 5 ? num : num - 4

  switch (rowNum) {
    case 1:
      return {
        className: cx('row', s.row, s.row1),
        imgClassName: cx(s.img, s.img1),
        shape: <Feature1Shape className={s.feature1Shape} />
      }
    case 2:
      return {
        className: cx('row', s.row, s.row2),
        imgClassName: cx(s.img, s.img2),
        shape: <Feature2Shape className={s.feature2Shape} />
      }
    case 3:
      return {
        className: cx('row', s.row, s.row3),
        imgClassName: cx(s.img, s.img3),
        shape: <Feature3Shape className={s.feature3Shape} />
      }
    case 4:
      return {
        className: cx('row', s.row, s.row4),
        imgClassName: cx(s.img, s.img4),
        shape: <Feature4Shape className={s.feature4Shape} />
      }
    default:
      return {
        className: cx('row', s.row, s.row1),
        imgClassName: cx(s.img, s.img1),
        shape: <Feature1Shape className={s.feature1Shape} />
      }
  }
}

const IndexFeatures = ({  data  }) => {
  const { title, items } = data

  return (
    <div className={s.featuresSection}>
      <div className="container">
        {items.map((item, index) => {
          const { className, imgClassName, shape } = getRowData(index)

          return (
            <div className={className} key={index}>
              <div className="col-lg-4">
                {index === 0 ? (
                  <Fade bottom>
                    <h3 className={cx('d-none d-lg-block', s.featureTitle)}>{title[0].text}</h3>
                  </Fade>
                ) : null}
                <Fade bottom cascade>
                  <div className={s.textBox}>
                    <h4 className={s.title}>{item.title[0].text}</h4>
                    <p className={s.description}>{item.description[0].text}</p>
                    <AniLink paintDrip duration={1} hex="#6b75ff" className={s.btn} to={linkResolver(item.linkUrl._meta)}> {/* `/features/${item.linkUrl._meta.uid}` */}
                      {item.linkText[0].text}
                    </AniLink>
                  </div>
                </Fade>
              </div>

              <div className="col-lg-8">
                <Fade bottom cascade>
                  <div className={s.imageBox}>
                    {shape}
                    <img className={imgClassName} src={item.image.url} alt={item.image.alt} />
                  </div>
                </Fade>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

IndexFeatures.propTypes = {
  data: propType.shape({
    title: propType.array,
    items: propType.array,
  })
}

export default IndexFeatures
