import React from 'react'
import cx from 'clsx'
import propType from 'prop-types'
import Fade from 'react-reveal/Fade'
import { RichText } from 'prismic-reactjs'

import { IndexHeroWave } from 'src/components/Svg'

import s from './indexHero.module.scss'

const IndexHero = ({  data  }) => {
  const { title, description, linkText, linkUrl, image } = data

  return (
    <div className={s.heroSection}>
      <Fade top cascade>
        <IndexHeroWave className={s.heroIcon}/>
      </Fade>

      <div className={s.wrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Fade left cascade>
                <div className={s.left}>
                  {RichText.render(title)}
                  {RichText.render(description)}

                  <a href={linkUrl.url} target="_blank" rel="noreferrer" className="link-inherit">
                    <button className={cx('btn btn-secondary', s.button)}>
                      {RichText.render(linkText)}
                    </button>
                  </a>
                </div>
              </Fade>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <Fade right cascade>
                <img src={image.url} alt={image.alt} className={s.bisFlowMainImage} />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

IndexHero.propTypes = {
  data: propType.shape({
    title: propType.array,
    description: propType.array,
    linkText: propType.array,
    linkUrl: propType.object,
    image: propType.object
  })
}

export default IndexHero
