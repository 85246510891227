import React from 'react'
import propType from 'prop-types'
import Fade from 'react-reveal/Fade'
import { RichText } from 'prismic-reactjs'

import s from './indexSolutions.module.scss'

const IndexSolutions = ({  data  }) => {
  const { items, image } = data

  return (
    <div className={s.solutionSection}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-6 d-none d-lg-block">
            <Fade bottom>
              <img src={image.url} alt={image.alt} className={s.solutionImage} />
            </Fade>
          </div>
          <div className="col-lg-7 col-12">
            <div className="row">
              {items.map((item, index) => (
                <div className="col-sm-6" key={index}>
                  <Fade bottom cascade>
                    {index === 0 ? (
                      <div className={s.titleBox}>
                        <h3 className={s.title}>
                          {RichText.render(item.text)}
                        </h3>
                      </div>
                    ) : (
                      <div className={s.box}>
                        {RichText.render(item.text)}
                      </div>
                    )}
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

IndexSolutions.propTypes = {
  data: propType.shape({
    items: propType.array,
    image: propType.object
  })
}

export default IndexSolutions
